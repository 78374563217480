<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">공지사항</h4>
            <span>공지사항을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="searchType">
                <option value="TC">제목 + 내용</option>
                <option value="T">제목</option>
                <option value="C">내용</option>
                <option value="W">작성자</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model.trim="searchWord">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <input type="date" class="form-control w-auto" v-model="startDate"/>
              <span class="m-1">~</span>
              <input type="date" class="form-control w-auto mr-1" v-model="endDate"/>
              <select class="form-control w-auto mr-1" v-model="notice">
                <option value="A">상단고정여부</option>
                <option value="Y">고정</option>
                <option value="N">미고정</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="deleted">
                <option value="0">노출여부</option>
                <option value="1">노출</option>
                <option value="2">미노출</option>
              </select>
              <button class="btn btn-primary" @click="goSearch()">검색</button>
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegister()">공지사항 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">공지사항 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th class="w-60px">No</th>
                    <th>등록일</th>
                    <th class="w-auto">제목</th>
                    <th>상단고정</th>
                    <th>노출여부</th>
                    <th>조회수</th>
                    <th>작성자</th>
                    <th>상세정보</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.notice === 'Y' ? '고정' : '미고정' }}</td>
                    <td>{{ item.deleted === 0 ? '노출' : '미노출' }}</td>
                    <td>{{ item.views }}</td>
                    <td>{{ item.name }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goDetail(item.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "NoticeList",
  path: "/support/notice",
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      page: 1,
      totalCount: 0, // 총 데이터 list length
      size: 10, // 한 페이지에 보여줄 size
      startDate: '',
      endDate: '',
      searchWord: '',
      searchType: 'TC',
      notice: 'A', //A: 전체, Y: 상단고정, N: 상단미고정
      deleted: '0' //0: 전체, 1: 노출, 2: 미노출
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'support', 'notice')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }
    if (this.$route.query.startDate) {
      this.startDate = this.$route.query.startDate
    }
    if (this.$route.query.endDate) {
      this.endDate = this.$route.query.endDate
    }
    if (this.$route.query.searchType) {
      this.searchType = this.$route.query.searchType
    }
    if (this.$route.query.searchWord) {
      this.searchWord = this.$route.query.searchWord
    }
    if (this.$route.query.notice) {
      this.notice = this.$route.query.notice
    }
    if (this.$route.query.deleted) {
      this.deleted = this.$route.query.deleted
    }

    this.getList(this.page)
  },
  methods: {
    goSearch() {
      let query = {
        page: 1 + '',
        size: this.size + ''
      }
      if (this.startDate !== '') {
        query.startDate = this.startDate
      }
      if (this.endDate !== '') {
        query.endDate = this.endDate
      }
      if (this.searchWord !== '') {
        query.searchType = this.searchType
        query.searchWord = this.searchWord
      }
      if (this.notice !== 'A') {
        query.notice = this.notice
      }
      if (this.deleted !== '0') {
        query.deleted = this.deleted
      }

      this.$router.replace({name: this.$options.name, query: query}).catch(error => {
        if(error.name !== "NavigationDuplicated" ){
          throw error
        }
      });
      this.getList(1)
    },
    getList(_page) {
      let query = ''
      if (this.startDate !== '') {
        query = query + '&startDate=' + this.startDate
      }
      if (this.endDate !== '') {
        query = query + '&endDate=' + this.endDate
      }
      if (this.searchWord !== '') {
        query = query + '&searchType=' + this.searchType + '&searchWord=' + this.searchWord
      }

      this.$get(this.$SUPPORT_NOTICE + '?perPage=' + this.size + '&page=' + _page + '&notice=' + this.notice + '&deleted=' + this.deleted + query, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total
        this.page = _page

        try {
          this.$refs.Pagination.init(this.$options.name, null, {
            startDate: this.startDate,
            endDate: this.endDate,
            searchType: this.searchType,
            searchWord: this.searchWord,
            notice: this.notice,
            deleted: this.deleted
          }, this.page, this.totalCount, Number(this.size))
        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      this.$router.push({name: 'NoticeWrite'})
    },
    goDetail(_id) {
      this.$router.push({name: 'NoticeDetail', query: {id: _id}})
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>
tr th {
  width: 100px;
}
</style>