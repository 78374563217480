<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">QNA 상세</h4>
            <span>1:1문의를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row sub-layout mt-0 mt-sm-3">
          <div class="container">
            <div class="row">
              <div class="col-6 board-write">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">작성자</span>
                  </div>
                  <span type="text" class="form-control">{{ name }}</span>
                  <div class="input-group-prepend">
                    <span class="input-group-text">작성일</span>
                  </div>
                  <span type="text" class="form-control">{{ createdDate }}</span>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">제목</span>
                  </div>
                  <span type="text" class="form-control">{{ title }}</span>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">내용</span>
                  </div>
                  <div class="form-control overflow-auto" v-html="content" style="height: 540px;"></div>
                </div>
              </div>
              <div class="col-6 board-write">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">상태</span>
                  </div>
                  <span type="text" class="form-control">{{ qnaState(state) }}</span>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">답변자</span>
                  </div>
                  <span type="text" class="form-control">{{ answer }}</span>
                  <div class="input-group-prepend">
                    <span class="input-group-text">답변일</span>
                  </div>
                  <span type="text" class="form-control">{{ answerDate }}</span>
                </div>
                <quill-editor class="mb-2" theme="snow" toolbar="full"
                              ref="quillEditor"/>
                <div class="d-flex mb-3">
                  <input type="button" class="btn btn-secondary ml-auto mr-2" value="취소" @click="$router.back()"/>
                  <input type="button" class="btn btn-success mr-auto" value="저장" @click="write()"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!-- /.container-fluid -->
    </div>
  </div>
</template>

<script>
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  name: "QnaDetail",
  path: "/support/qna",
  components: {
    QuillEditor
  },
  data() {
    return {
      name: '',
      createdDate: '',
      title: '',
      content: '',

      isEdit: false,
      answerId: 0,
      answer: null,
      answerContent: '',
      answerDate: '',
      state: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'support', 'qna')
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$SUPPORT_QNA_DETAIL + this.$route.query.id, this.$options.name + '_getData', (result) => {
        this.name = result.data.name
        this.createdDate = result.data.created_at
        this.title = result.data.title
        this.content = result.data.content

        this.answerId = result.data.answer_id
        this.answer = result.data.answer
        this.answerDate = result.data.answer_at
        this.answerContent = result.data.answer_content
        this.state = result.data.state

        if (this.answerContent !== null) {
          this.$refs.quillEditor.setHTML(this.answerContent)
        }

        if (this.answerDate !== null) {
          this.isEdit = true
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    write() {
      let content = this.$refs.quillEditor.getHTML()
      if (content === '<p><br></p>') {
        this.notify('error', '답변 내용을 입력해주세요.')
        return
      }

      let queryString = '?title=' + '[RE]' + this.title + '&content=' + encodeURIComponent(this.$refs.quillEditor.getHTML())

      if (!this.isEdit) { // 등록
        queryString = queryString + '&id=' + this.$route.query.id

        this.$post(this.$SUPPORT_QNA_REGISTER + queryString, this.$options.name + '_write', null, () => {
          this.notify('success', '답변이 등록되었습니다.')
          this.$router.back()

        }, (result) => {
          this.httpError(result)
        })

      } else { // 수정
        this.$put(this.$SUPPORT_QNA_EDIT + this.answerId + queryString, this.$options.name + '_write', null, () => {
          this.notify('success', '답변이 수정되었습니다.')
          this.$router.back()

        }, (result) => {
          this.httpError(result)
        })
      }
    },
  },
}
</script>

<style scoped>
.input-group-prepend span {
  width: 80px;
}
</style>