export default Object.freeze({
    install(Vue) {
        // 토스트 스타일 알림
        Vue.config.globalProperties.notify = function (type, msg, is_alert = false) {
            if (is_alert) {
                alert(msg)
            } else {
                let originMsg = window.$('#alert-wrap div:first-child span').text()

                if (originMsg !== msg) {
                    if (type === 'error') {
                        window.$('#alert-wrap').append("<div class=\"alert-content bg-red\"><span>" + msg + "</span></div>")
                    } else {
                        window.$('#alert-wrap').append("<div class=\"alert-content\"><span>" + msg + "</span></div>")
                    }
                    setTimeout(function () {
                        window.$('#alert-wrap div:first-child').remove()
                    }, 3000)
                }
            }
        }

        // 기본 HTTP 에러 처리
        Vue.config.globalProperties.httpError = function (data) {
            console.log(data)
            if (data.status === 401) {
                this.notify('error', data.data.msg, false)

                this.$router.push({name: 'Login'}).catch(() => {
                })
            } else {
                if (data.data.msg !== '') {
                    this.notify('error', data.data.msg, false)
                }
            }
        }

        // 토큰 갱신
        // Vue.config.globalProperties.refreshTokenRate = 5 * 60 * 1000
        // Vue.config.globalProperties.refreshToken = function () {
        //     let refreshTokan = sessionStorage.getItem('refresh_token')
        //     if (!refreshTokan) {
        //         return
        //     }
        //
        //     let formData = new FormData()
        //     formData.append('refreshToken', refreshTokan)
        //
        //     this.$post(this.$REFRESHTOKEN, 'refreshToken', formData, (result) => {
        //         sessionStorage.setItem('token', result.data.accessToken)
        //         sessionStorage.setItem('refresh_token', result.data.refreshToken)
        //
        //         setTimeout(() => {
        //             this.refreshToken()
        //         }, this.refreshTokenRate)
        //     }, (result) => {
        //         this.httpError(result)
        //     })
        // }

        Vue.config.globalProperties.doBack = function (back, name = 'Dashboard', redirect = false) {
            if (typeof back !== 'undefined') {
                this.$router.back()
            } else {
                if (redirect) {
                    this.$router.push({name: name})
                } else {
                    this.$router.replace({name: name})
                }
            }
        }

        Vue.config.globalProperties.getFormatData = function (data, result) {
            for (const key of Object.keys(data)) {
                if (typeof result.data[key] === 'number') {
                    data[key] = result.data[key].toString()
                } else if (typeof result.data[key] === 'boolean') {
                    data[key] = result.data[key] ? true : false
                } else if (typeof result.data[key] === 'undefined') {
                    data[key] = ''
                } else if (result.data[key] === null) {
                    data[key] = ''
                } else {
                    data[key] = result.data[key]
                }
            }
        }

        // 비밀번호 정규식 체크
        Vue.config.globalProperties.validatePw = function (password) {
            let reg_pw = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*?[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,30}/

            if (reg_pw.test(password)) { // 비밀번호 정규식체크
                return true
            }
            return false
        }

        // 이메일 정규식 체크
        Vue.config.globalProperties.validateEmail = function (email) {
            if ((/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))) {
                return true
            }
            return false
        }
    }
})
