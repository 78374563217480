<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">코드 관리</h4>
            <span>코드를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <input type="text" class="form-control w-auto mr-1" placeholder="카테고리 검색" v-model.trim="searchCategory">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="goSearch()">검색</button>
              <div class="ml-auto">
                <button class="btn btn-success mr-1" @click="goRegister()">코드 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">코드 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>카테고리</th>
                    <th>코드</th>
                    <th>스킨</th>
                    <th>라벨</th>
                    <th>사용</th>
                    <th>수정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.skin }}</td>
                    <td>{{ item.label }}</td>
                    <td class="position-relative">
                      <input class="position-absolute" type="checkbox" :checked="item.deleted === 0">
                      <div class="btn-check" @click="useCode(index)"></div>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goEdit(index)">수정하기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="codeAddModal" tabindex="-1" aria-labelledby="codeAddModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="codeAddModalLabel">코드 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 모든 항목을 입력해주세요.</p>
            <div class="pb-1">
              <input type="text" class="form-control" v-model.trim="addCategory" placeholder="*카테고리">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addCode" placeholder="*코드">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addSkin" placeholder="*스킨">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addLabel" placeholder="*라벨">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="codeEditModal" tabindex="-1" aria-labelledby="codeEditModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="codeEditModalLabel">코드 수정 ({{ editCategory + '/' + editCode }})</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 수정하실 항목을 변경해주세요</p>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*스킨</span>
              </div>
              <input type="text" class="form-control" v-model.trim="editSkin" placeholder="*스킨">
            </div>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*라벨</span>
              </div>
              <input type="text" class="form-control" v-model.trim="editLabel" placeholder="*라벨">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="저장" @click="edit()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination"

export default {
  name: "CodeList",
  path: "/setting/code/list",
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      page: 1,
      totalCount: 0, // 총 데이터 list length
      size: 10, // 한 페이지에 보여줄 size
      searchCategory: '',

      /*등록*/
      addCategory: '',
      addCode: '',
      addSkin: '',
      addLabel: '',

      /*수정*/
      editCategory: '',
      editCode: '',
      editSkin: '',
      editLabel: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'setting', 'code')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }
    if (this.$route.query.searchCategory) {
      this.searchCategory = this.$route.query.searchCategory
    }
    this.getList(this.page)
  },
  methods: {
    goSearch() {
      let query = {
        page: 1 + '',
        size: this.size + ''
      }
      if (this.searchCategory !== '') {
        query.searchCategory = this.searchCategory
      }

      this.$router.replace({name: this.$options.name, query: query}).catch(error => {
        if(error.name !== "NavigationDuplicated" ){
          throw error
        }
      });
      this.getList(1)
    },
    getList(_page) {
      let query = ''

      if (this.searchCategory !== '') {
        query = query + '&category=' + this.searchCategory
      }

      this.$get(this.$SETTING_CODE + '?page=' + _page + '&perPage=' + this.size + query, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total

        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {
            searchCategory: this.searchCategory
          }, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      /*등록*/
      this.addCategory = ''
      this.addCode = ''
      this.addSkin = ''
      this.addLabel = ''

      window.$('#codeAddModal').modal('show');
    },
    register() {
      if (this.addCategory === '') {
        this.notify('error', '카테고리를 입력해주세요.')
        return
      }
      if (this.addCode === '') {
        this.notify('error', '코드를 입력해주세요.')
        return
      }
      if (this.addSkin === '') {
        this.notify('error', '스킨을 입력해주세요.')
        return
      }
      if (this.addLabel === '') {
        this.notify('error', '라벨을 입력해주세요.')
        return
      }

      let queryString = '?category=' + this.addCategory + '&code=' + this.addCode + '&skin=' + this.addSkin + '&label=' + this.addLabel

      this.$post(this.$SETTING_CODE_REGISTER + queryString, this.$options.name + '_register', null, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        window.$('#codeAddModal').modal('hide');
        this.getList(1)

      }, (result) => {
        this.httpError(result)
      })
    },
    goEdit(_index) {
      let item = this.items[_index]
      this.editCategory = item.category
      this.editCode = item.code
      this.editSkin = item.skin
      this.editLabel = item.label

      window.$('#codeEditModal').modal('show');
    },
    edit() {
      if (this.editSkin === '') {
        this.notify('error', '스킨을 입력해주세요.')
        return
      }
      if (this.editLabel === '') {
        this.notify('error', '라벨을 입력해주세요.')
        return
      }

      let queryString = '?category=' + this.editCategory + '&code=' + this.editCode + '&skin=' + this.editSkin + '&label=' + this.editLabel

      this.$put(this.$SETTING_CODE_EDIT + queryString, this.$options.name + '_edit', null, () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        window.$('#codeEditModal').modal('hide');
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    },
    useCode(_idx) {
      /* 0:사용, 1:미사용 */
      let item = this.items[_idx]
      let deleted = item.deleted
      if (deleted === 0) {
        deleted = 1
      } else {
        deleted = 0
      }

      this.$delete(this.$SETTING_CODE_DELETE + item.category + '/' + item.code + '?deleted=' + deleted, this.$options.name + '_useCode', () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        this.items[_idx].deleted = deleted

      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>