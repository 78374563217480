<template>
  <aside class="main-sidebar elevation-4"
         :class="{'sidebar-dark-primary' : isDarkMode, 'sidebar-light-white' : !isDarkMode}">
    <!-- Brand Logo -->
    <router-link :to="{ name: 'Main' }" class="brand-link">
      <img src="dist/img/AdminLTELogo.png" alt="ADMIN Logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-bold">마이티켓 </span>
      <span class="brand-text font-weight-light">ADMIN</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">

      <!-- Sidebar Menu -->
      <nav class="mt-2 pt-3">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li v-for="(navItem, index) in lnb" :key="index" class="nav-item"
              v-bind:class="{ 'menu-open' : selectedCode === navItem.code }">
            <a class="nav-link" v-bind:class="{ active : selectedCode === navItem.code}">
              <i class="nav-icon" v-bind:class="navItem.icon"></i>
              <p>
                {{ navItem.title }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-show="selectedCode === navItem.code">
              <li v-for="(subItem, subIndex) in navItem.sub" :key="subIndex" class="nav-item">
                <router-link :to="{ name: subItem.link }" class="nav-link"
                             v-bind:class="{ active : selectedSubCode === subItem.subCode}">
                  <p>{{ subItem.subTitle }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false,
      selectedCode: null,
      selectedSubCode: null,
      lnb: [
        {
          'icon': 'fas fa-wallet',
          'title': '매입 관리',
          'code': 'order',
          'sub': [
            {
              'subTitle': '매입 내역',
              'subCode': 'order',
              'link': 'OrderList',
            },
          ]
        },
        {
          'icon': 'fa fa-ticket-alt',
          'title': '상품권 관리',
          'code': 'business',
          'sub': [
            {
              'subTitle': '상품권 등록/수정',
              'subCode': 'rate',
              'link': 'PurchaseRate',
            },
            {
              'subTitle': '사이트 계정 관리',
              'subCode': 'account',
              'link': 'AccountManage',
            },
          ]
        },
        {
          'icon': 'fas fa-headset',
          'title': '고객센터',
          'code': 'support',
          'sub': [
            {
              'subTitle': '공지사항',
              'subCode': 'notice',
              'link': 'NoticeList',
            },
            {
              'subTitle': 'FAQ',
              'subCode': 'faq',
              'link': 'FaqList',
            },
            {
              'subTitle': '1:1문의',
              'subCode': 'qna',
              'link': 'QnaList',
            },
          ]
        },
        {
          'icon': 'fas fa-cog',
          'title': '설정',
          'code': 'setting',
          'sub': [
            {
              'subTitle': '사이트 정보 관리',
              'subCode': 'site',
              'link': 'SiteInfo',
            },
            {
              'subTitle': '관리자 계정 관리',
              'subCode': 'admin',
              'link': 'AdminAccount',
            },
            {
              'subTitle': '코드 관리',
              'subCode': 'code',
              'link': 'CodeList',
            },
          ]
        },
      ],
    }
  },
  created() {
    this.emitter.on(this.$EVENT_LNB_CHANGE, (_code, _subCode) => {
      this.selectedCode = _code
      this.selectedSubCode = _subCode
    })

    if (localStorage.getItem('isDarkMode') === '1') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }
    this.emitter.on(this.$EVENT_MODE_CHANGE, () => {
      if (localStorage.getItem('isDarkMode') === '1') {
        this.isDarkMode = true
      } else {
        this.isDarkMode = false
      }
    })
  },
}
</script>

<style>
</style>
