<template>
  <div id="app" class="wrapper" :class="{'dark-mode' : isDarkMode}">
    <vue-header v-if="!$route.meta.hideLNBHeader" ref="Header"></vue-header>
    <vue-lnb v-show="!$route.meta.hideLNBHeader"></vue-lnb>
    <div :class="{'content-wrapper' : !$route.meta.hideLNBHeader}">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <div id="alert-wrap" class="alert-wrap"></div>
  </div>
</template>

<script>

import LNB from "@/components/common/LNB";
import Header from "@/components/common/Header";

export default {
  name: 'App',
  data() {
    return {
      isDarkMode: false,
    }
  },
  components: {
    'vue-lnb': LNB,
    'vue-header': Header,
  },
  created() {
    // let token = sessionStorage.getItem('token')
    // if(token) {
    //   this.refreshToken()
    // }

    if (localStorage.getItem('isDarkMode') === '1') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }

    this.emitter.on(this.$EVENT_MODE_CHANGE, () => {
      if (localStorage.getItem('isDarkMode') === '1') {
        this.isDarkMode = true
      } else {
        this.isDarkMode = false
      }
    })

  },

  methods: {
  },
  beforeCreate() {
    let console=(function(oldCons){
      return {
        log: function(){
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.log.apply(console, arguments)
          }
        },
        warn: function() {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.warn.apply(console, arguments)
          }
        },
        error: function() {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.error.apply(console, arguments)
          }
        },
      };
    }(window.console));
    window.console = console
  }
}
</script>

<style>
.bg-red {
  background: rgba(255, 18, 18, 0.7) !important;
}

.custom-file-input ~ .custom-file-label::after {
  content: "파일 선택";
}

.ql-editor {
  font-size: 1rem;
}

.ql-container {
  height: 400px !important;
}

.ql-container.ql-snow {
  background: #fff;
}

.wrapper .content-wrapper {
  min-height: calc(100vh - 57px);
}
</style>
