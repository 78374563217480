<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">상품권 등록/수정</h4>
            <!--            <h4 class="m-0">매입 비율</h4>-->
            <span>※ 지급금액 = 액면가격 * 매입비율 - 송금수수료</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success mr-1" @click="goRegister()">상품권 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->

      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">상품권 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>상품권 이름</th>
                    <th>매입비율</th>
                    <th>이미지</th>
                    <th>API</th>
                    <th>최대충전금액</th>
                    <th>노출</th>
                    <th>수정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.ticket_name }}</td>
                    <td>{{ item.purchase_rate }}%</td>
                    <td>{{ item.ticket_img }}</td>
                    <td>{{ item.call_api }}</td>
                    <td v-if="item.charge === 0">무제한</td>
                    <td v-else>{{ numberWithCommas(item.charge) }}원</td>
                    <td class="position-relative">
                      <input class="position-absolute" type="checkbox" :checked="item.deleted === 0">
                      <div class="btn-check" @click="useRate(index)"></div>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goEdit(index)">수정하기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="ticketAddModal" tabindex="-1" aria-labelledby="ticketAddModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ticketAddModalLabel">상품권 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* API 외 항목은 필수입니다.</p>
            <div class="input-group pb-1">
              <input type="text" class=" form-control" v-model.trim="addTicket" placeholder="*상품권 이름">
            </div>
            <div class="input-group pb-1">
              <input inputmode="numeric" type="text" class=" form-control" v-model.trim="addRate" placeholder="*매입비율"
                     maxlength="3">
            </div>
            <div class="input-group pb-1">
              <input inputmode="numeric" type="text" class=" form-control" v-model.trim="addCharge" placeholder="*최대충전금액(0:무제한)">
            </div>
            <div class="input-group pb-1">
              <div class="custom-file">
                <input ref="addImgFileUploader" id="addImgFile" type="file" class="custom-file-input" accept="image/*"
                       @change="upload($event, 0)"/>
                <label v-if="addImgFile === null" class="custom-file-label text-truncate pr-90" for="addImgFile" style="color: #999;">*상품권
                  이미지</label>
                <label v-else class="custom-file-label text-truncate pr-90" for="addImgFile">{{ addFileName }}</label>
              </div>
            </div>
            <div class="input-group pb-1">
              <input type="text" class=" form-control" v-model.trim="addApi" placeholder="API">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="ticketEditModal" tabindex="-1" aria-labelledby="ticketEditModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ticketEditModalLabel">상품권 정보 수정</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 수정하실 항목을 변경해주세요</p>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*상품권 이름</span>
              </div>
              <input type="text" class=" form-control" v-model.trim="editTicket">
            </div>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*매입비율</span>
              </div>
              <input inputmode="numeric" type="text" class="form-control" v-model.trim="editRate"
                     maxlength="3">
            </div>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*최대충전금액(0:무제한)</span>
              </div>
              <input inputmode="numeric" type="text" class=" form-control" v-model.trim="editCharge">
            </div>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*상품권 이미지</span>
              </div>
              <div class="custom-file">
                <input ref="editImgFileUploader" id="editImgFile" type="file" class="custom-file-input" accept="image/*"
                       @change="upload($event, 1)"/>
                <label class="custom-file-label text-truncate pr-90" for="editImgFile">{{ editFileName }}</label>
              </div>
            </div>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">API</span>
              </div>
              <input type="text" class=" form-control" v-model.trim="editApi">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="저장" @click="edit()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "PurchaseRate",
  path: "/business/rate",
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      size: 10,
      page: 1,
      totalCount: 0,
      lastPage: 0,

      /*추가*/
      addTicket: '',
      addRate: '',
      addImgFile: null,
      addFileName: '',
      addApi: '',
      addCharge: '',

      /*수정*/
      editTicket: '',
      editRate: '',
      editImgFile: null,
      editFileName: '',
      editApi: '',
      editCharge: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'business', 'rate')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }

    this.getList(this.page)
  },
  methods: {
    getList(_page) {
      this.$get(this.$BUSINESS_RATE + '?page=' + _page + '&perPage=' + this.size, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total
        this.lastPage = result.data.last_page

        this.page = _page

        for (let i = 0; i < this.items.length; i++) {
          let imgUrl = this.items[i].ticket_img.split('/')
          this.items[i].ticket_img = imgUrl[imgUrl.length - 1]
        }

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {}, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    upload(e, _type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (_type === 0) { /*추가*/
        this.addImgFile = files[0]
        this.addFileName = files[0].name

        this.$refs.addImgFileUploader.value = ''

      } else { /*수정*/
        this.editImgFile = files[0]
        this.editFileName = files[0].name

        this.$refs.editImgFileUploader.value = ''
      }
    },
    useRate(_idx) {
      /* 0:사용, 1:미사용 */
      let item = this.items[_idx]
      let deleted = item.deleted
      if (deleted === 0) {
        deleted = 1
      } else {
        deleted = 0
      }

      this.$delete(this.$BUSINESS_RATE_DELETE + item.id + '?deleted=' + deleted, this.$options.name + '_useRate', () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        this.items[_idx].deleted = deleted

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      this.addTicket = ''
      this.addRate = ''
      this.addImgFile = null
      this.addFileName = ''
      this.addApi = ''
      this.addCharge = ''

      window.$('#ticketAddModal').modal('show');
    },
    register() {
      if (this.addTicket === '') {
        this.notify('error', '상품권 이름을 입력해주세요.')
        return
      }
      if (this.addRate === '') {
        this.notify('error', '매입비율을 입력해주세요.')
        return
      }
      if ((this.addRate * 1) <= 0) {
        this.notify('error', '매입비율은 0보다 커야합니다.')
        return
      }
      if ((this.addRate * 1) > 100) {
        this.notify('error', '매입비율은 100보다 클 수 없습니다.')
        return
      }
      if (this.addCharge === '') {
        this.notify('error', '최대충전금액을 입력해주세요.')
        return
      }
      if (this.addImgFile === null) {
        this.notify('error', '상품권 이미지를 선택해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('ticketName', this.addTicket)
      formData.append('ticketImg', this.addImgFile)
      formData.append('purchaseRate', (this.addRate * 1))
      formData.append('charge', (this.addCharge * 1))

      if (this.addApi !== '') {
        formData.append('callApi', this.addApi)
      }

      this.$post(this.$BUSINESS_RATE_REGISTER, this.$options.name + '_register', formData, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        window.$('#ticketAddModal').modal('hide');
        this.getList(this.lastPage)

      }, (result) => {
        this.httpError(result)
      })
    },
    goEdit(_index) {
      let item = this.items[_index]
      this.id = item.id
      this.editTicket = item.ticket_name
      this.editRate = item.purchase_rate
      this.editImgFile = null
      this.editFileName = item.ticket_img
      this.editCharge = item.charge

      if (item.call_api === null) {
        this.editApi = ''
      } else {
        this.editApi = item.call_api
      }

      window.$('#ticketEditModal').modal('show');
    },
    edit() {
      if (this.editTicket === '') {
        this.notify('error', '상품권 이름을 입력해주세요.')
        return
      }
      if (this.editRate === '') {
        this.notify('error', '매입비율을 입력해주세요.')
        return
      }
      if ((this.editRate * 1) <= 0) {
        this.notify('error', '매입비율은 0보다 커야합니다.')
        return
      }
      if ((this.editRate * 1) > 100) {
        this.notify('error', '매입비율은 100보다 클 수 없습니다.')
        return
      }
      if (this.editCharge === '') {
        this.notify('error', '최대충전가능금액을 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('ticketName', this.editTicket)
      formData.append('purchaseRate', (this.editRate * 1))
      formData.append('charge', (this.editCharge * 1))

      if (this.editImgFile !== null) {
        formData.append('ticketImg', this.editImgFile)
      }
      if (this.editApi !== ''){
        formData.append('callApi', this.editApi)
      }

      this.$post(this.$BUSINESS_RATE_EDIT + this.id, this.$options.name + '_edit', formData, () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        window.$('#ticketEditModal').modal('hide');
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    addRate() {
      if (!(/^[0-9]*$/.test(this.addRate))) {
        this.addRate = /([0-9]*)/.exec(this.addRate)[0]
      }
    },
    editRate() {
      if (!(/^[0-9]*$/.test(this.editRate))) {
        this.editRate = /([0-9]*)/.exec(this.editRate)[0]
      }
    },
    addCharge() {
      if (!(/^[0-9]*$/.test(this.addCharge))) {
        this.addCharge = /([0-9]*)/.exec(this.addCharge)[0]
      }
    },
    editCharge() {
      if (!(/^[0-9]*$/.test(this.editCharge))) {
        this.editCharge = /([0-9]*)/.exec(this.editCharge)[0]
      }
    },
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>