import axios from 'axios'

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithFile = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
// let requestOptionWithTokenForJson = (method, data) => {
//     return {
//         method: method,
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
//             'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
//         },
//         timeout: 60000,
//         body: data
//     }
// }
// let requestOptionForDownload = () => {
//     return {
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
//         },
//         timeout: 60000,
//         responseType: 'blob',
//     }
// }
//
// let requestOptionWithTokenForDownload = () => {
//     return {
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
//             'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
//         },
//         timeout: 60000,
//         responseType: 'blob',
//     }
// }

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN + "/admin"

        Vue.config.globalProperties.$AUTH = "/auth" /*인증-로그인*/
        Vue.config.globalProperties.$MAIN = "" /*메인-대시보드*/

        Vue.config.globalProperties.$ORDER = "/order" /*매입관리-매입내역*/
        Vue.config.globalProperties.$ORDER_DETAIL = "/order/" /*매입관리-매입내역 상세*/

        Vue.config.globalProperties.$BUSINESS_RATE = "/business/rate" /*상품권 조회*/
        Vue.config.globalProperties.$BUSINESS_RATE_REGISTER = "/business/rate" /*상품권 등록*/
        Vue.config.globalProperties.$BUSINESS_RATE_EDIT = "/business/rate/" /*상품권 수정*/
        Vue.config.globalProperties.$BUSINESS_RATE_DELETE = "/business/rate/" /*상품권 노출 여부 변경*/

        Vue.config.globalProperties.$BUSINESS_ACCOUNT_TICKET = "/business/account/ticket" /*사이트 계정 관리 상품권 리스트 조회*/
        Vue.config.globalProperties.$BUSINESS_ACCOUNT = "/business/account" /*사이트 계정 조회*/
        Vue.config.globalProperties.$BUSINESS_ACCOUNT_DETAIL = "/business/account/" /*사이트 계정 상세 조회*/
        Vue.config.globalProperties.$BUSINESS_ACCOUNT_REGISTER = "/business/account" /*사이트 계정 등록*/
        Vue.config.globalProperties.$BUSINESS_ACCOUNT_EDIT = "/business/account/" /*사이트 계정 수정*/
        Vue.config.globalProperties.$BUSINESS_ACCOUNT_DELETE = "/business/account/"  /*사이트 계정 삭제*/
        Vue.config.globalProperties.$BUSINESS_ACCOUNT_REP = "/business/account/" /*사이트 대표 계정 변경 /id/rep*/

        Vue.config.globalProperties.$SUPPORT_NOTICE = "/support/notice" /*고객센터-공지사항*/
        Vue.config.globalProperties.$SUPPORT_NOTICE_DETAIL = "/support/notice/" /*고객센터-공지사항 상세*/
        Vue.config.globalProperties.$SUPPORT_NOTICE_REGISTER = "/support/notice" /*고객센터-공지사항 등록*/
        Vue.config.globalProperties.$SUPPORT_NOTICE_EDIT = "/support/notice/" /*고객센터-공지사항 수정*/

        Vue.config.globalProperties.$SUPPORT_FAQ = "/support/faq" /*고객센터-faq*/
        Vue.config.globalProperties.$SUPPORT_FAQ_DETAIL = "/support/faq/" /*고객센터-faq 상세*/
        Vue.config.globalProperties.$SUPPORT_FAQ_REGISTER = "/support/faq" /*고객센터-faq 등록*/
        Vue.config.globalProperties.$SUPPORT_FAQ_EDIT = "/support/faq/" /*고객센터-faq 수정*/

        Vue.config.globalProperties.$SUPPORT_QNA = "/support/qna" /*고객센터-qna*/
        Vue.config.globalProperties.$SUPPORT_QNA_DETAIL = "/support/qna/" /*고객센터-qna 상세*/
        Vue.config.globalProperties.$SUPPORT_QNA_REGISTER = "/support/qna" /*고객센터-qna 답변 등록*/
        Vue.config.globalProperties.$SUPPORT_QNA_EDIT = "/support/qna/" /*고객센터-qna 답변 수정*/

        Vue.config.globalProperties.$SETTING_ADMIN = "/setting/admin" /*관리자 계정 관리 리스트 */
        Vue.config.globalProperties.$SETTING_ADMIN_EDIT = "/setting/admin/" /*관리자 계정 관리 수정*/
        Vue.config.globalProperties.$SETTING_ADMIN_REGISTER = "/setting/admin" /*관리자 계정 관리 등록*/
        Vue.config.globalProperties.$SETTING_ADMIN_REPRESENTATIVE = "/setting/admin/" /*관리자 계정 관리 대표 계정 변경*/
        Vue.config.globalProperties.$SETTING_ADMIN_DELETE = "/setting/admin/" /*관리자 계정 관리 삭제 변경*/

        Vue.config.globalProperties.$SETTING_SITEINFO = "/setting/siteInfo" /*사이트 정보 관리*/
        Vue.config.globalProperties.$SETTING_SITEINFO_EDIT = "/setting/siteInfo" /*사이트 정보 관리 수정*/

        Vue.config.globalProperties.$SETTING_CODE = "/setting/code" /*코드 관리 리스트*/
        Vue.config.globalProperties.$SETTING_CODE_EDIT = "/setting/code" /*코드 관리 수정*/
        Vue.config.globalProperties.$SETTING_CODE_REGISTER = "/setting/code" /*코드 관리 등록*/
        Vue.config.globalProperties.$SETTING_CODE_DELETE = "/setting/code/" /*코드 상태 변경 category/code*/

        Vue.config.globalProperties.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                if (callUrl.includes(this.$BUSINESS_RATE_REGISTER) || callUrl.includes(this.$BUSINESS_RATE_EDIT) || callUrl.includes(this.$SETTING_SITEINFO_EDIT)) {
                    _requestOption = requestOptionWithFile()
                } else {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.status === 200 || response.status === 201 || response.status === 205) {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false

                if (response.status === 205) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false

                if (response.status === 204) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false

                if (response.status === 200) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        // Vue.config.globalProperties.$postJson = async function (callUrl, caller, method, data, success, fail){
        //     console.log(arguments[0])
        //     if (pending_post[arguments[0] + caller]) {
        //         return
        //     }
        //     pending_post[arguments[0] + caller] = true
        //     let _requestOption = requestOptionWithTokenForJson(method, data)
        //
        //     fetch(this.$DOMAIN + callUrl, _requestOption)
        //         .then(response => {
        //             pending_post[arguments[0] + caller] = false
        //             success(response)
        //         }).catch(e => {
        //         pending_post[arguments[0] + caller] = false
        //         fail(e)
        //     })
        // }

        // Vue.config.globalProperties.$getJson = async function (callUrl, caller, method, success, fail){
        //     console.log(arguments[0])
        //     if (pending_post[arguments[0] + caller]) {
        //         return
        //     }
        //     pending_post[arguments[0] + caller] = true
        //     let _requestOption = requestOptionWithTokenForJson(method)
        //
        //     fetch(this.$DOMAIN + callUrl, _requestOption)
        //         .then(response => {
        //             pending_post[arguments[0] + caller] = false
        //             let promise = new Promise(resolve => {
        //                 resolve(response.json())
        //             })
        //             promise.then((result) => {
        //                 success(result)
        //             })
        //         }).catch(e => {
        //         pending_post[arguments[0] + caller] = false
        //         fail(e)
        //     })
        // }

        // Vue.config.globalProperties.$fileDownload = function (response){
        //     let filename = response.headers['content-disposition'].split('filename=')[1].split('"')[1]
        //     const url = window.URL.createObjectURL(new Blob([response.data]))
        //     const link = document.createElement('a')
        //     link.href = url
        //     link.setAttribute('download', filename)
        //     link.innerText = '다운로드'
        //     return [link, filename]
        // }
        // Vue.config.globalProperties.$download = function (callUrl, caller, useToken, success, fail){
        //     console.log(arguments[0])
        //     if (pending_delete[arguments[0] + caller]) {
        //         return
        //     }
        //     pending_delete[arguments[0] + caller] = true
        //
        //     let _requestOption = requestOptionForDownload()
        //     if (useToken) {
        //         let token = sessionStorage.getItem('token')
        //         if (token && token.length > 0) {
        //             _requestOption = requestOptionWithTokenForDownload()
        //         } else {
        //             this.$router.replace({name: 'Login'})
        //         }
        //     }
        //     axios.get(this.$DOMAIN + callUrl, _requestOption).then(response => {
        //         pending_get[arguments[0] + caller] = false
        //         if (response.headers['content-type'] === 'application/octet-stream') {
        //             success(response)
        //         } else {
        //             fail(response.data)
        //         }
        //     }).catch(e => {
        //         pending_get[arguments[0] + caller] = false
        //         fail(e.response)
        //         if(e.response.data.status === 403) {
        //             this.$router.replace({name: 'Login'})
        //             sessionStorage.removeItem('token')
        //             this.$store.state.isLoggedIn = false
        //         }
        //     })
        //
        // }

        // Vue.config.globalProperties.$downloadExcelData = function (tableId, filename) {
        //     if (filename == null || typeof filename == undefined)
        //         filename = tableId;
        //         filename += ".csv";
        //
        //     let BOM = "\uFEFF";
        //
        //     let table = document.getElementById(tableId);
        //     let csvString = BOM;
        //     for (let rowCnt = 0; rowCnt < table.rows.length; rowCnt++) {
        //         let rowData = table.rows[rowCnt].cells;
        //         for (let colCnt = 0; colCnt < rowData.length; colCnt++) {
        //             let columnData = rowData[colCnt].textContent;
        //             if (columnData == null || columnData.length === 0) {
        //                 if(colCnt === 0) {
        //                     continue
        //                 } else {
        //                     columnData = "".replace(/"/g, '""');
        //                 }
        //                 // columnData = "".replace(/"/g, '""');
        //             }
        //             else {
        //                 columnData = columnData.toString().replace(/"/g, '""'); // escape double quotes
        //             }
        //             csvString = csvString + '"' + columnData + '",';
        //         }
        //         csvString = csvString.substring(0, csvString.length - 1);
        //         csvString = csvString + "\r\n";
        //     }
        //     csvString = csvString.substring(0, csvString.length - 1);
        //
        //     // IE 10, 11, Edge Run
        //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //         let blob = new Blob([decodeURIComponent(csvString)], {
        //             type: 'text/csv;charset=utf8'
        //         });
        //         window.navigator.msSaveOrOpenBlob(blob, filename);
        //     } else if (window.Blob && window.URL) {
        //         // HTML5 Blob
        //         let blob = new Blob([csvString], { type: 'text/csv;charset=utf8' });
        //         let csvUrl = URL.createObjectURL(blob);
        //         let a = document.createElement('a');
        //         a.setAttribute('style', 'display:none');
        //         a.setAttribute('href', csvUrl);
        //         a.setAttribute('download', filename);
        //         document.body.appendChild(a);
        //         a.click()
        //         a.remove();
        //     } else {
        //         // Data URI
        //         let csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csvString);
        //         let blob = new Blob([csvString], { type: 'text/csv;charset=utf8' });
        //         let csvUrl = URL.createObjectURL(blob);
        //         console.log(csvUrl)
        //         let a = document.createElement('a');
        //         a.setAttribute('style', 'display:none');
        //         a.setAttribute('target', '_blank');
        //         a.setAttribute('href', csvData);
        //         a.setAttribute('download', filename);
        //         document.body.appendChild(a);
        //         a.click()
        //         a.remove();
        //     }
        // }

        // img
        // Vue.config.globalProperties.$download_get = function (callUrl, caller, img, others, fail){
        //     console.log(arguments[0])
        //     if (pending_post[arguments[0] + caller]) {
        //         return
        //     }
        //     pending_post[arguments[0] + caller] = true
        //     let _requestOption = requestOptionForDownload()
        //     let token = sessionStorage.getItem('token')
        //     if(token && token.length > 0) {
        //         _requestOption = requestOptionWithTokenForDownload
        //     }
        //
        //     axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
        //         pending_post[arguments[0] + caller] = false
        //         if (response.headers['content-type'] === 'application/octet-stream' || response.headers['content-type'] === 'image') {
        //             img(response.request.responseURL)
        //             // } else if(response.headers['content-type'] === 'text/plain') {
        //             //     others(response.data)
        //             // } else {
        //             //     fail(response.data)
        //         } else {
        //             others(response.data)
        //         }
        //     }).catch(e => {
        //         pending_get[arguments[0] + caller] = false
        //         fail(e.response)
        //         if(e.response.data.status === 403) {
        //             this.$router.replace({name: 'Login'})
        //             sessionStorage.removeItem('token')
        //             // this.$store.state.isLoggedIn = false
        //         }
        //     })
        // }
    }
})
