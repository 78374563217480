<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">매입 상세</h4>
            <span>매입 상세 내용을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">매입 상세 정보</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>주문번호</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ id }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>처리상태</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ orderState(status) }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>신청일</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ createdDate }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>완료일</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ updatedDate }}</span>
                        </div>
                      </div>
                      <div class="mb-2 border-bottom"></div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>매입 총금액</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ numberWithCommas(purchase) }}원</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>매입 수수료</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ numberWithCommas(purchaseFee) }}원</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>이체 수수료</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ numberWithCommas(transferFee) }}원</span>
                        </div>
                      </div>

                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>입금(예정) 금액</strong>
                        </div>
                        <div class="col-sm-6">
                          <span v-if="purchase > 0">{{ numberWithCommas(purchase - ( purchaseFee + transferFee )) }}원</span>
                          <span v-else>0원</span>
                        </div>
                      </div>
                      <div class="mb-2 border-bottom"></div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>입금 은행</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ bankName }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>입금 계좌</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ account }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>고객명</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ name }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>휴대폰 번호</strong>
                        </div>
                        <div class="col-sm-6">
                          <span>{{ phone }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->

          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">상품권 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body overflow-auto" style="height: 466px;">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <!-- 반복 -->
                      <div v-for="(item, index) in items" :key="index">
                        <div class="row align-items-center mb-2">
                          <div class="col-sm-3 text-right">
                            <strong>상품권</strong>
                          </div>
                          <div class="col-sm-6">
                            <span>{{ item.ticket_name }}</span>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-sm-3 text-right">
                            <strong>상품권 번호</strong>
                          </div>
                          <div class="col-sm-6">
                            <span>{{ item.pin_number }}</span>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-sm-3 text-right">
                            <strong>처리상태</strong>
                          </div>
                          <div class="col-sm-6">
                            <span>{{ ticketState(item.status) }}</span>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-sm-3 text-right">
                            <strong>메모</strong>
                          </div>
                          <div class="col-sm-6">
                            <span>{{ item.memo }}</span>
                          </div>
                        </div>
                        <div class="mb-2 border-bottom"></div>
                      </div>
                      <!-- //반복 -->
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->

          <div class="col-12">
            <div class="d-flex mt-3 mb-3">
              <input type="button" class="btn btn-secondary ml-auto mr-auto" value="목록으로" @click="$router.back()"/>
            </div>
          </div>

        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "OrderDetail",
  path: "/order/detail",
  data(){
    return {
      id: 0,
      status: '',
      createdDate: '',
      updatedDate: '',
      purchase: 0,
      purchaseFee: 0,
      transferFee: 0,
      bankName: '',
      account: '',
      name: '',
      phone: '',
      items: [],
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'order', 'order')
  },
  mounted() {
    this.id = this.$route.query.id

    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$ORDER_DETAIL + this.id, this.$options.name + '_getData', (result) => {
        this.status = result.data.status
        this.createdDate = result.data.created_at
        this.updatedDate = result.data.updated_at
        this.purchase = result.data.purchase
        this.purchaseFee = result.data.purchase_fee
        this.transferFee = result.data.transfer_fee
        this.bankName = result.data.bank_name
        this.account = result.data.customer_account
        this.name = result.data.customer_name
        this.phone = result.data.customer_phone

        this.items = result.data.tickets

      }, (result) => {
        this.httpError(result)
      })
    }
  },
}
</script>

<style scoped>

</style>