<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사이트 계정 관리</h4>
            <span>상품권 사이트에 연결되는 계정을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegister()">신규 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">계정 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>상품권</th>
                    <th>노출</th>
                    <th>대표계정</th>
                    <th>계정관리</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.ticket_name }}</td>
                    <td>{{ item.deleted === 0 ? '노출' : '미노출' }}</td>
                    <td>{{ item.tu_id }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goDetail(item.id, item.ticket_name)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="siteAddModal" tabindex="-1" aria-labelledby="siteAddModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="siteAddModalLabel">계정 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 메모 외 항목은 필수입니다.</p>
            <div class="pb-1">
              <select v-model="addTicketId" class="form-control">
                <option value="">상품권을 선택해주세요.</option>
                <option v-for="(ticket, index) in ticketList" :key="index" :value="ticket.id">
                  {{ ticket.ticket_name }}
                </option>
              </select>
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addId" placeholder="*아이디">
            </div>
            <div class="input-group pb-1">
              <input type="password" class="form-control" v-model.trim="addPw"
                     placeholder="*비밀번호">
            </div>
            <div class="input-group pb-1">
              <input type="text" class=" form-control" v-model.trim="addMemo" placeholder="메모">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "AccountManage",
  path: "/business/account",
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      size: 10,
      page: 1,
      totalCount: 0,
      lastPage: 0,

      /*추가*/
      ticketList: [],
      addTicketId: '',
      addId: '',
      addPw: '',
      addMemo: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'business', 'account')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }

    this.getList(this.page)
    this.getTicketList()
  },
  methods: {
    getTicketList() {
      this.$get(this.$BUSINESS_ACCOUNT_TICKET, this.$options.name + '_getTicketList', (result) => {
        this.ticketList = result.data

      }, (result) => {
        this.httpError(result)
      })
    },
    getList(_page) {
      this.$get(this.$BUSINESS_ACCOUNT + '?page=' + _page + '&perPage=' + this.size, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total
        this.lastPage = result.data.last_page

        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {}, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      this.addTicketId = ''
      this.addId = ''
      this.addPw = ''
      this.addMemo = ''

      window.$('#siteAddModal').modal('show');
    },
    register() {
      if (this.addTicketId === ''){
        this.notify('error', '상품권을 선택해주세요.')
        return
      }
      if (this.addId === '') {
        this.notify('error', '아이디를 입력해주세요.')
        return
      }
      if (this.addPw === '') {
        this.notify('error', '비밀번호를 입력해주세요.')
        return
      }

      let queryString = '?ticketId=' + this.addTicketId + '&id=' + this.addId + '&password=' + encodeURIComponent(this.addPw)
      if (this.addMemo !== '') {
        queryString = queryString + '&memo=' + this.addMemo
      }

      this.$post(this.$BUSINESS_ACCOUNT_REGISTER + queryString, this.$options.name + '_register', null, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        window.$('#siteAddModal').modal('hide');
        this.getList(this.lastPage)

      }, (result) => {
        this.httpError(result)
      })
    },
    goDetail(_id, _name) {
      this.$router.push({name: 'AccountManageDetail', query: {id: _id, name: _name}})
    },
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  }
}
</script>

<style scoped>

</style>