<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">공지사항</h4>
            <span>공지사항을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row sub-layout mt-0 mt-sm-3">
          <div class="container">
            <div class="row">
              <div class="col-12 board-write">
                <input type="text" class="form-control mb-2" v-model.trim="title" placeholder="제목을 입력하세요.">
                <quill-editor class="mb-2" theme="snow" toolbar="full"
                              ref="quillEditor"/>
                <div class="row mb-3">
                  <div class="col-4 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">상단고정</span>
                      </div>
                      <select v-model="notice" class="form-control">
                        <option value="N">상단 미고정</option>
                        <option value="Y">상단 고정</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input type="button" class="btn btn-secondary ml-auto mr-2" value="취소" @click="$router.back()"/>
                  <input type="button" class="btn btn-success mr-auto" value="작성" @click="write()"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!-- /.container-fluid -->
    </div>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  name: "NoticeWrite",
  path: "/support/notice/write",
  components: {
    QuillEditor
  },
  data() {
    return {
      title: '',
      notice: 'N', //Y: 고정, N: 미고정
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'support', 'notice')
  },
  methods: {
    write() {
      if (this.title === '') {
        this.notify('error', '제목을 입력해주세요.')
        return
      }
      let content = this.$refs.quillEditor.getHTML()
      if (content === '<p><br></p>') {
        this.notify('error', '내용을 입력해주세요.')
        return
      }

      let queryString = '?title=' + this.title + '&content=' + encodeURIComponent(this.$refs.quillEditor.getHTML()) + '&notice=' + this.notice

      this.$post(this.$SUPPORT_NOTICE_REGISTER + queryString, this.$options.name + '_write', null, () => {
        this.notify('success', '공지사항이 등록되었습니다.')
        this.$router.back()

      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>