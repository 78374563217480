<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h5 class="m-0">{{ today }}</h5>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <h5 class="m-0">오늘 매입 정보</h5>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-6">
            <!--            <span class="m-0">신규 문의 : 10건</span>-->
          </div><!-- /.col -->
          <div class="col-sm-6">
            <span class="m-0">건수 : {{ numberWithCommas(cnt) }} 건 | 금액 : {{ numberWithCommas(price) }} 원</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title d-flex">
                  매입 내역
                  <span class="badge btn btn-success ml-3" @click="$router.push({name: 'OrderList'})">자세히 보기</span></h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>주문번호</th>
                    <th>신청일</th>
                    <th>완료일</th>
                    <th>신청인</th>
                    <th class="w-auto">신청내용</th>
                    <th>매입 금액</th>
                    <th>입금 금액</th>
                    <th>수수료</th>
                    <th>상태</th>
                    <th>상세정보</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(order, index) in orderList" :key="index">
                    <td>{{ order.id }}</td>
                    <td>{{ order.created_at }}</td>
                    <td>{{ order.updated_at }}</td>
                    <td>{{ order.customer_name }}</td>
                    <td>{{ order.ticket_name + ' ' + order.cnt + '건' }}</td>
                    <td v-if="order.status === '0'"></td> <!-- 0: 접수대기 -->
                    <td v-else>{{ numberWithCommas(order.purchase) }}원</td>
                    <td v-if="order.status === '0'"></td> <!-- 0: 접수대기 -->
                    <td v-else-if="order.purchase === 0">
                      0원
                    </td>
                    <td v-else>
                      {{ numberWithCommas(order.purchase - (order.purchase_fee + order.transfer_fee)) }}원
                    </td>
                    <td v-if="order.status === '0'"></td> <!-- 0: 접수대기 -->
                    <td v-else>
                      {{ numberWithCommas(order.purchase_fee + order.transfer_fee) }}원
                    </td>
                    <td>{{ orderState(order.status) }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goOrderDetail(order.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title d-flex">
                  신규 문의
                  <span class="badge btn btn-success ml-3" @click="$router.push({name:'QnaList'})">더보기</span>
                </h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>등록일</th>
                    <th class="w-auto">제목</th>
                    <th>작성자</th>
                    <th>상태</th>
                    <th>답변자</th>
                    <th>답변일</th>
                    <th>상세정보</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(qna, index) in qnaList" :key="index">
                    <td>{{ qna.created_at }}</td>
                    <td>{{ qna.title }}</td>
                    <td>{{ qna.name }}</td>
                    <td>{{ qnaState(qna.state) }}</td>
                    <td>{{ qna.answer }}</td>
                    <td>{{ qna.answer_at }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goQnaDetail(qna.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Main",
  path: "/main",
  data() {
    return {
      orderSize: 5,
      qnaSize: 5,
      today: '',
      cnt: 0,
      price: 0,
      orderList: [],
      qnaList: [],
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, null, null)
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let queryString = '?orderPerPage=' + this.orderSize + '&qnaPerPage=' + this.qnaSize

      this.$get(this.$MAIN + queryString, this.$options.name + '_getList', (result) => {
        this.orderList = result.data.orders
        this.qnaList = result.data.qnas
        this.cnt = result.data.condition.cnt
        this.price = result.data.condition.price
        this.today = result.data.condition.today

      }, (result) => {
        this.httpError(result)
      })
    },
    goOrderDetail(_id) {
      this.$router.push({name: 'OrderDetail', query: {id: _id}})
    },
    goQnaDetail(_id) {
      this.$router.push({name: 'QnaDetail', query: {id: _id}})
    }
  },
}
</script>

<style scoped>

</style>