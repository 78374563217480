<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">매입 내역</h4>
            <span>매입 내역을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="searchType">
                <option value="I">주문번호</option>
                <option value="W">신청인</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model.trim="searchWord">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <input type="date" class="form-control w-auto" v-model="startDate"/>
              <span class="m-1">~</span>
              <input type="date" class="form-control w-auto mr-1" v-model="endDate"/>
              <select class="form-control w-auto mr-1" v-model="status">
                <option value="">처리상태</option>
                <option value="0">접수대기</option>
                <option value="1">입금완료</option>
                <option value="2">일부입금</option>
                <option value="3">처리불가</option>
              </select>
              <button class="btn btn-primary" @click="goSearch()">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">매입 내역</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th class="w-60px">No</th>
                    <th class="w-60px">주문번호</th>
                    <th>신청일</th>
                    <th>완료일</th>
                    <th>신청인</th>
                    <th class="w-auto">신청내용</th>
                    <th>매입 금액</th>
                    <th>입금 금액</th>
                    <th>수수료</th>
                    <th>상태</th>
                    <th>상세정보</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.id }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.updated_at }}</td>
                    <td>{{ item.customer_name }}</td>
                    <td>{{ item.ticket_name + ' ' + item.cnt + '건' }}</td>
                    <td v-if="item.status === '0'"></td> <!-- 0: 접수대기 -->
                    <td v-else>{{ numberWithCommas(item.purchase) }}원</td>
                    <td v-if="item.status === '0'"></td> <!-- 0: 접수대기 -->
                    <td v-else-if="item.purchase === 0">
                      0원
                    </td>
                    <td v-else>
                      {{ numberWithCommas(item.purchase - (item.purchase_fee + item.transfer_fee)) }}원
                    </td>
                    <td v-if="item.status === '0'"></td> <!-- 0: 접수대기 -->
                    <td v-else>
                      {{ numberWithCommas(item.purchase_fee + item.transfer_fee) }}원
                    </td>
                    <td>{{ orderState(item.status) }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goDetail(item.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "OrderList",
  path: "/order",
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'order', 'order')
  },
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      size: 10,
      page: 1,
      totalCount: 0,
      startDate: '',
      endDate: '',
      status: '',
      searchType: 'I',
      searchWord: '',
    }
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }
    if (this.$route.query.startDate) {
      this.startDate = this.$route.query.startDate
    }
    if (this.$route.query.endDate) {
      this.endDate = this.$route.query.endDate
    }
    if (this.$route.query.searchType) {
      this.searchType = this.$route.query.searchType
    }
    if (this.$route.query.searchWord) {
      this.searchWord = this.$route.query.searchWord
    }
    if (this.$route.query.status) {
      this.status = this.$route.query.status
    }

    this.getList(this.page)
  },
  methods: {
    goSearch() {
      let query = {
        page: 1 + '',
        size: this.size + ''
      }
      if (this.startDate !== '') {
        query.startDate = this.startDate
      }
      if (this.endDate !== '') {
        query.endDate = this.endDate
      }
      if (this.searchWord !== '') {
        query.searchType = this.searchType
        query.searchWord = this.searchWord
      }
      if (this.status !== '') {
        query.status = this.status
      }

      this.$router.replace({name: this.$options.name, query: query}).catch(error => {
        if (error.name !== "NavigationDuplicated") {
          throw error
        }
      });
      this.getList(1)
    },
    getList(_page) {
      let queryString = ''
      if (this.startDate !== '') {
        queryString = queryString + '&startDate=' + this.startDate
      }
      if (this.endDate !== '') {
        queryString = queryString + '&endDate=' + this.endDate
      }
      if (this.searchWord !== '') {
        queryString = queryString + '&searchType=' + this.searchType + '&searchWord=' + this.searchWord
      }
      if (this.status !== '') {
        queryString = queryString + '&status=' + this.status
      }

      this.$get(this.$ORDER + '?perPage=' + this.size + '&page=' + _page + queryString, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total
        this.page = _page

        try {
          this.$refs.Pagination.init(this.$options.name, null, {
            startDate: this.startDate,
            endDate: this.endDate,
            searchType: this.searchType,
            searchWord: this.searchWord,
            status: this.status
          }, this.page, this.totalCount, Number(this.size))
        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goDetail(_id) {
      this.$router.push({name: 'OrderDetail', query: {id: _id}})
    },
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>
tr th {
  width: 100px;
}
</style>