export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.numberWithCommas = function (number) {
            if (!(number + '').includes('.')) {
                return (number + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
                return `${((number + '').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(number + '').split('.')[1]}`
            }
        }

        Vue.config.globalProperties.orderState = function (status){
            if (status === '0') {
                return '접수대기'
            } else if (status === '1') {
                return '입금완료'
            } else if (status === '2') {
                return '일부입금'
            } else if (status === '3') {
                return '처리불가'
            }
            return ''
        }

        Vue.config.globalProperties.ticketState = function (status){
            if (status === '0') {
                return '접수대기'
            } else if (status === '1') {
                return '입금완료'
            } else if (status === '2') {
                return '처리불가'
            }
            return ''
        }

        Vue.config.globalProperties.qnaState = function (status) {
            if (status === 0) {
                return '답변대기'
            } else if (status === 1) {
                return '답변완료'
            }
            return ''
        }
    }
})
