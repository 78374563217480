import {createRouter, createWebHashHistory} from "vue-router"

import AccountManage from '@/components/business/AccountManage'
import PurchaseRate from '@/components/business/PurchaseRate'
import Login from '@/components/Login'
import Main from '@/components/Main'
import OrderDetail from '@/components/order/OrderDetail'
import OrderList from '@/components/order/OrderList'
import AdminAccount from '@/components/setting/AdminAccount'
import SiteInfo from '@/components/setting/SiteInfo'
import FaqDetail from '@/components/support/Faq/FaqDetail'
import FaqList from '@/components/support/Faq/FaqList'
import FaqWrite from '@/components/support/Faq/FaqWrite'
import NoticeDetail from '@/components/support/Notice/NoticeDetail'
import NoticeList from '@/components/support/Notice/NoticeList'
import NoticeWrite from '@/components/support/Notice/NoticeWrite'
import QnaDetail from '@/components/support/Qna/QnaDetail'
import QnaList from '@/components/support/Qna/QnaList'
import AccountManageDetail from "@/components/business/AccountManageDetail";
import CodeList from "@/components/setting/CodeList";

export default createRouter({
    history: createWebHashHistory(),//2.x대를 설치하시면 작동을 안합니다.
    scrollBehavior(){
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                hideLNBHeader: true
            },
        },
        {
            path: '/main',
            name: 'Main',
            component: Main,
        },
        {
            path: '/order',
            name: 'OrderList',
            component: OrderList,
        },
        {
            path: '/order/detail',
            name: 'OrderDetail',
            component: OrderDetail,
        },
        {
            path: '/business/rate',
            name: 'PurchaseRate',
            component: PurchaseRate,
        },
        {
            path: '/business/account',
            name: 'AccountManage',
            component: AccountManage,
        },
        {
            path: '/business/account/detail',
            name: 'AccountManageDetail',
            component: AccountManageDetail,
        },
        {
            path: '/support/faq',
            name: 'FaqList',
            component: FaqList,
        },
        {
            path: '/support/faq/detail',
            name: 'FaqDetail',
            component: FaqDetail,
        },
        {
            path: '/support/faq/write',
            name: 'FaqWrite',
            component: FaqWrite,
        },
        {
            path: '/support/notice',
            name: 'NoticeList',
            component: NoticeList,
        },
        {
            path: '/support/notice/detail',
            name: 'NoticeDetail',
            component: NoticeDetail,
        },
        {
            path: '/support/notice/write',
            name: 'NoticeWrite',
            component: NoticeWrite,
        },
        {
            path: '/support/qna',
            name: 'QnaList',
            component: QnaList,
        },
        {
            path: '/support/qna/detail',
            name: 'QnaDetail',
            component: QnaDetail,
        },
        {
            path: '/setting/admin',
            name: 'AdminAccount',
            component: AdminAccount,
        },
        {
            path: '/setting/siteinfo',
            name: 'SiteInfo',
            component: SiteInfo,
        },
        {
            path: '/setting/code/list',
            name: 'CodeList',
            component: CodeList,
        },
    ]
})