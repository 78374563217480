import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import api from "@/commonjs/api";
import common from '@/commonjs/common';
import event from '@/commonjs/event';
import filter from '@/commonjs/filter';
import mitt from "mitt";

router.beforeEach((to, from, next) => {
    let tokenMiddle = [
        'Main',
        'AccountManage',
        'AccountManageDetail',
        'PurchaseRate',
        'OrderList',
        'OrderDetail',
        'AdminAccount',
        'CodeList',
        'SiteInfo',
        'FaqList',
        'FaqDetail',
        'FaqWrite',
        'NoticeList',
        'NoticeDetail',
        'NoticeWrite',
        'QnaList',
        'QnaDetail'
    ]

    if (to.name !== 'Login'){
        if (tokenMiddle.indexOf(to.name) === -1) {
            let token = sessionStorage.getItem('token')
            if (!token) {
                next({name: 'Login'})
            }
        }
    }

    next()
})

const app = createApp(App)

app.use(router)
app.use(api)
app.use(common)
app.use(event)
app.use(filter)

let emitter = mitt()
app.config.globalProperties.emitter = emitter

app.mount('#app')