<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사이트 정보 관리</h4>
            <span>사이트 정보를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">사이트 정보</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>사이트 명</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.site_name"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>로고 이미지</strong>
                        </div>
                        <div class="col-sm-6 input-group">
                          <div class="custom-file">
                            <input ref="logoFileUploader" id="logoFile" type="file" class="custom-file-input" accept="image/*" @change="upload($event, 0)"/>
                            <label class="custom-file-label" for="logoFile">{{ info.site_top_logo }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>파비콘 이미지</strong>
                        </div>
                        <div class="col-sm-6 input-group">
                          <div class="custom-file">
                            <input ref="faviconFileUploader" id="faviconFile" type="file" class="custom-file-input" accept="image/*" @change="upload($event, 1)"/>
                            <label class="custom-file-label" for="faviconFile">{{ info.site_favicon }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>상호명</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.company_name"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>대표</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.company_ceo"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>주소</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.company_address"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>사업자 등록번호</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.business_number"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>고객센터 전화번호</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.customer_tel"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>고객센터 운영시간</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.customer_hours"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>시스템 점검시간</strong>
                        </div>
                        <div class="col-sm-6">
                          <input type="text" class="form-control" v-model.trim="info.system_check_time"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>이체 수수료</strong>
                        </div>
                        <div class="col-sm-6">
                          <input inputmode="numeric" type="text" class="form-control" v-model.trim="fee" maxlength="10"/>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 text-right">
                          <strong>교환권 수령번호</strong>
                        </div>
                        <div class="col-sm-6">
                          <input inputmode="numeric" type="text" class="form-control" v-model.trim="info.transfer_hp" maxlength="20"/>
                        </div>
                      </div>
                      <div class="text-center">
                        <button type="button" class="btn btn-primary" @click="edit()">수정</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SiteInfo",
  path: "/setting/siteinfo",
  data() {
    return {
      info: {
        site_name: '',
        site_top_logo: '',
        site_favicon: '',
        company_name: '',
        company_ceo: '',
        company_address: '',
        business_number: '',
        customer_tel: '',
        customer_hours: '',
        system_check_time: '',
        transfer_fee: 0,
        transfer_hp: '',
      },
      fee: '',
      logoFile: null,
      faviconFile: null,
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'setting', 'site')
  },
  mounted() {
    this.getSiteInfo()
  },
  methods: {
    getSiteInfo() {
      this.$get(this.$SETTING_SITEINFO, this.$options.name + '_getSiteInfo', (result) => {
        this.info = result.data
        this.fee = result.data.transfer_fee

        /*로고*/
        let logoUrl = this.info.site_top_logo.split('/')
        this.info.site_top_logo = logoUrl[logoUrl.length - 1]

        /*파비콘*/
        let faviconUrl = this.info.site_favicon.split('/')
        this.info.site_favicon = faviconUrl[faviconUrl.length - 1]

      }, (result) => {
        this.httpError(result)
      })
    },
    upload(e, _type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (_type === 0) { /*로고*/
        this.logoFile = files[0]
        this.info.site_top_logo = files[0].name

        this.$refs.logoFileUploader.value = ''

      } else { /*파비콘*/
        this.faviconFile = files[0]
        this.info.site_favicon = files[0].name

        this.$refs.faviconFileUploader.value = ''
      }
    },
    edit() {
      if (this.info.site_name === '') {
        this.notify('error', '사이트 명을 입력해주세요.')
        return
      }
      if (this.info.company_name === '') {
        this.notify('error', '상호명을 입력해주세요.')
        return
      }
      if (this.info.company_ceo === '') {
        this.notify('error', '대표를 입력해주세요.')
        return
      }
      if (this.info.company_address === '') {
        this.notify('error', '주소를 입력해주세요.')
        return
      }
      if (this.info.business_number === '') {
        this.notify('error', '사업자 등록번호를 입력해주세요.')
        return
      }
      if (this.info.customer_tel === '') {
        this.notify('error', '고객센터 전화번호를 입력해주세요.')
        return
      }
      if (this.info.customer_hours === '') {
        this.notify('error', '고객센터 운영시간을 입력해주세요.')
        return
      }
      if (this.info.system_check_time === '') {
        this.notify('error', '시스템 점검시간을 입력해주세요.')
        return
      }
      if (this.fee === '') {
        this.notify('error', '이체 수수료를 입력해주세요.')
        return
      }
      if (this.info.transfer_hp === '') {
        this.notify('error', '교환권 수령번호를 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('id', this.info.id)
      formData.append('siteName', this.info.site_name)
      formData.append('companyName', this.info.company_name)
      formData.append('companyCeo', this.info.company_ceo)
      formData.append('companyAddress', this.info.company_address)
      formData.append('businessNumber', this.info.business_number)
      formData.append('customerTel', this.info.customer_tel)
      formData.append('customerHours', this.info.customer_hours)
      formData.append('systemCheckTime', this.info.system_check_time)
      formData.append('transferFee', this.fee)
      formData.append('transferHp', this.info.transfer_hp)

      if (this.logoFile !== null) {
        formData.append('siteTopLogo', this.logoFile)
      }

      if (this.faviconFile !== null) {
        formData.append('siteFavicon', this.faviconFile)
      }

      this.$post(this.$SETTING_SITEINFO_EDIT, this.$options.name + '_edit', formData, () => {
        this.notify('success', '성공적으로 변경되었습니다.')

        this.logoFile = null
        this.faviconFile = null

        this.getSiteInfo()

      }, (result) => {
        this.httpError(result)
      })
    },
  },
  watch: {
    fee() {
      if (!(/^[0-9]*$/.test(this.fee))) {
        this.fee = /([0-9]*)/.exec(this.fee)[0]
      }
    },
  },
}
</script>

<style scoped>

</style>