<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사이트 계정 상세</h4>
            <span>상품권 사이트에 연결되는 계정을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">계정 목록({{ name }})</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>아이디</th>
                    <th>잔고</th>
                    <th>메모</th>
                    <th>대표계정</th>
                    <th>사용</th>
                    <th>수정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.tu_id }}</td>
                    <td>{{ numberWithCommas(item.tu_balance) }}원</td>
                    <td>{{ item.tu_memo }}</td>
                    <td class="position-relative">
                      <input class="position-absolute" type="radio" :id="item.no" name="use" :value="item.no"
                             :checked="item.representative === 'Y'">
                      <div class="btn-check" @click="setRep(item.user_id)"></div>
                    </td>
                    <td class="position-relative">
                      <input class="position-absolute" type="checkbox" :checked="item.deleted === 0">
                      <div class="btn-check" @click="useAccount(index)"></div>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goEdit(item)">수정</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="manageModal" tabindex="-1" aria-labelledby="manageModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="manageModalLabel">계정정보 수정 ({{ editId }})</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 수정하실 항목을 입력해주세요</p>
            <div class="input-group pb-1">
              <input type="password" class="form-control" v-model.trim="editPw" placeholder="비밀번호">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="editMemo" placeholder="메모">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="저장" @click="edit()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "AccountManageDetail",
  path: "/business/account/detail",
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      size: 10,
      page: 1,
      totalCount: 0,

      /*수정*/
      id: 0,
      editId: '',
      editPw: '',
      editMemo: '',

      name: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'business', 'account')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }
    if (this.$route.query.name) {
      this.name = this.$route.query.name
    }

    this.getList(this.page)
  },
  methods: {
    getList(_page) {
      this.$get(this.$BUSINESS_ACCOUNT_DETAIL + this.$route.query.id + '?page=' + _page + '&perPage=' + this.size, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total

        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {}, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goEdit(_item) {
      this.id = _item.user_id
      this.editId = _item.tu_id
      this.editPw = ''
      this.editMemo = ''

      window.$('#manageModal').modal('show');
    },
    edit() {
      if (this.editPw === '' && this.editMemo === '') {
        this.notify('error', '수정하실 항목을 입력해주세요.')
        return
      }

      let queryString = ''
      if (this.editPw !== '') {
        queryString = queryString + '&password=' + encodeURIComponent(this.editPw)
      }
      if (this.editMemo !== '') {
        queryString = queryString + '&memo=' + this.editMemo
      }

      queryString = queryString.replace('&', '?')

      this.$put(this.$BUSINESS_ACCOUNT_EDIT + this.id + queryString, this.$options.name + '_edit', null, () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        window.$('#manageModal').modal('hide');
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    },
    setRep(_id) {
      this.$put(this.$BUSINESS_ACCOUNT_REP + _id + '/rep', this.$options.name + '_setRep', null, () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    },
    useAccount(_idx) {
      /* 0:사용, 1:미사용 */
      let item = this.items[_idx]
      let deleted = item.deleted
      if (deleted === 0) {
        deleted = 1
      } else {
        deleted = 0
      }

      this.$delete(this.$BUSINESS_ACCOUNT_DELETE + item.user_id + '?deleted=' + deleted, this.$options.name + '_useAccount', () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        this.items[_idx].deleted = deleted

      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  }
}
</script>

<style scoped>

</style>